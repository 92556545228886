import { createStyles, Group, Paper, Text, ThemeIcon, SimpleGrid, Anchor, useMantineTheme } from '@mantine/core';
import { IconArrowUpRight, IconArrowDownRight } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

interface ToolLinksProps {
  data: ToolLink[];
}

export interface ToolLink {
  title: string;
  value: string;
  link: string;
  depreciated?: boolean;
  alternative?: string;
}

export function ToolLinks({ data }: ToolLinksProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const stats = data.map(({title, value, link, depreciated, alternative}) => {
    return (
      <Paper withBorder p="md" radius="md" key={title} style={{width:' 100%', backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,}}>
        <Anchor href={link} rel="noreferrer noopener">
          <Group position="apart">
            <div>
              <Text weight={700} size="xl"  td="underline">
                {title}
              </Text>
              <Text
                color="dimmed"
                weight={700}
                size="xs"
                className={classes.label}
              >
                {value}
              </Text>
            </div>
          </Group>
        </Anchor>
        { !!depreciated &&
            <Text color="dimmed" size="sm" mt="md">
              <Text component="div" transform='uppercase' color="red" weight={700}>
                Depreciated
              </Text>
              <Text component="div">
                This tool may not function correctly or at all.
              </Text>
              {!!alternative && 
                  <Text><Anchor href={alternative} style={{textDecoration: 'underline'}}>{alternative}</Anchor> provides similar functionality.</Text>
              }
            </Text>
        }

      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={1} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {stats}
      </SimpleGrid>
    </div>
  );
}