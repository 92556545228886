import { ToolLink, ToolLinks } from '../Components/ToolLinks';
import { Container, Text, Title } from '@mantine/core';

const data: ToolLink[] = [
  {
    title: 'Add to Keplr',
    value: 'Add various testnets to Keplr wallet.',
    link: 'https://connect.trivium.network',
  },
  {
    title: 'SNIP-20 History',
    value: 'View the internal transfer history of your SNIP-20 tokens.',
    link: 'https://snip20history.trivium.network',
  },
  {
    title: 'Secret Transaction Decrypter',
    value: 'Decrypt your Secret Network transactions made with Keplr wallet. View inputs and outputs.',
    link: 'https://secret-tx-decrypter.trivium.network',
  },
  {
    title: 'CosmWasm DevTools for Secret',
    value: 'A fork of https://cosmwasm.tools for Secret Network. Interact with and deploy contracts.',
    link: 'https://contract-devtools.trivium.network',
  },
  {
    title: 'SNIP-721 Viewer',
    value: 'Decrypt and view the full metadata of your own SNIP-721 NFTs. View the public data of any NFT.',
    link: 'https://secret-nft-viewer.trivium.network',
  },
  {
    title: 'SecretSwap Liquidity',
    value: 'View your total SecretSwap liquidity and all pools you are providing liquidity to.',
    link: 'https://secretswapliquidity.trivium.network',
    depreciated: true,
    alternative: 'https://cryptoclerk.xyz',
  }
];

const MainContent = () => {
  return (
    <Container>
      <section id="tools">
        <Text align="center">
          <Title order={1}>Trivium Tools</Title>
        </Text>

        <Text align="center">
          A collection of tools for Secret Network.
        </Text>
        <ToolLinks data={data}/>
      </section>
    </Container>
  );
};

export default MainContent;