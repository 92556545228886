import About from '../Components/About';
import SectionOne from '../Components/SectionOne';
import SectionTwo from '../Components/SectionTwo';
import SectionThree from '../Components/SectionThree';
import SectionFour from '../Components/SectionFour';
import SectionFive from '../Components/SectionFive';
import OurMission from '../Components/Mission';
import FAQ from '../Components/FAQ';
import OurProducts from '../Components/Products';
import Networks from '../Components/Networks';
import ContactUs from '../Components/Contact';
import OurServices from '../Components/Services';

const MainContent = () => {
  return (
    <>
      <About />

      <OurMission />

      <OurServices />

      <OurProducts />

      {/* <SectionTwo />

            <SectionThree /> */}

      <Networks />

      <FAQ />

      <ContactUs />
      { /* Other sections .. */ }
    </>
  );
};

export default MainContent;