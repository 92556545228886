import { useMantineTheme, Container, Text, Title, Grid, Image, Button } from '@mantine/core';
import { Link } from 'react-scroll';
import chains from '../Data/chains';

const OurServices = () => {
  const theme = useMantineTheme();
  const svgStyle = theme.colorScheme === 'dark' ? {filter: 'invert(70%)'} : {};

  return (
    <section id="services">
      <Container>
        <Text align="center">
          <Title order={1} mb="30px">Our Services</Title>
        </Text>

        <Grid>
          <Grid.Col xs={4} sm={4} md={4} lg={4} style={{padding: '32px'}}>
            <Image src="bank.svg" alt={'RPC'} style={svgStyle} />
          </Grid.Col>

          <Grid.Col xs={8} sm={8} md={8} lg={8}>
            <Title order={2} mb="30px">Non-Custodial Staking</Title>

            <Text size="sm" mb="24px">
              You can stake with us on {chains.length} different Cosmos networks. Our reliable infrastructure and slash protection insurance ensures your stake is safe.
            </Text>
            <Link to="networks" smooth duration={500}>
              <Button type="button" variant="default" radius="lg" size="md">Stake now</Button>
            </Link>
          </Grid.Col>
        </Grid>

        <Grid>

          <Grid.Col xs={8} sm={8} md={8} lg={8}>
            <Title order={2} mb="30px">Whitelabel RPC</Title>

            <Text size="sm" mb="8px">
              Trivium can provide private RPC API clusters for most chains. Our Always-In-Sync technology ensures requests are always served by an in-sync node, eliminating stale data and providing a superior experience to users and developers.
            </Text>

            <Text size="sm">
              <a href='#contact'>Contact us</a> today to learn more about RPC services for your chain or dApp.
            </Text>
          </Grid.Col>

          <Grid.Col xs={4} sm={4} md={4} lg={4} style={{padding: '32px'}}>
            <Image src="code-json.svg" alt={'RPC'} style={svgStyle} />
          </Grid.Col>

        </Grid>

        <Grid>

          <Grid.Col xs={4} sm={4} md={4} lg={4} style={{padding: '32px'}}>
            <Image src="link-lock.svg" alt={'RPC'} style={svgStyle} />
          </Grid.Col>

          <Grid.Col xs={8} sm={8} md={8} lg={8}>
            <Title order={2} mb="30px">Validator as a Service</Title>

            <Text size="sm" mb="8px">
              Trivium can run and maintain most Cosmos and Tendermint validators, allowing you offer staking services with full control of branding and commissions. Every validator we run comes with 24 hour monitoring, timely network upgrades, and a 99% uptime guarantee.
            </Text>

            <Text size="sm">
              <a href='#contact'>Contact us</a> today to learn more about starting your own validator with us.
            </Text>
          </Grid.Col>

        </Grid>

      </Container>
    </section>
  );
};

export default OurServices;