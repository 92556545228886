import { Carousel } from '@mantine/carousel';
import { Text, Container, useMantineTheme, Title, Image, Anchor } from '@mantine/core';
import '../Styles/Products.scss';

import { FaTools } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const OurProducts = () => {
  const theme = useMantineTheme();

  const carouselContent = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    borderRadius: 15,
    gap: 15,
    padding: '10%'
        
  };
    

  return (
    <section id="products">
      <Container>
        <Text align="center" mb="15px">
          <Title order={1}>Our Products</Title>
        </Text>

        <Text align="center" mb="25px">
          A few examples of the projects we have built.
        </Text>

        <Carousel
          withIndicators
          //height={"auto"}
          slideSize="33.333333%"
          slideGap="md"
          breakpoints={[
            { maxWidth: 'sm', slideSize: '50%' },
            { maxWidth: 'xs', slideSize: '100%' },
          ]}
          loop
          align="start"
          pr="10px"
          pl="10px"
        >
          <Carousel.Slide>
            <div style={carouselContent}>
              <div className="">
                <img src='architech-banner.jpg' alt={'Architech'} />
              </div>
              <div style={{height: '50%'}}>
                <Title order={2}>Architech</Title>
                <Text>
                  Architech is a one-stop shop for Web3 communities on Archway offering an NFT marketplace and DAO tooling.<br/>
                  <Anchor href="https://architech.zone">https://architech.zone</Anchor>
                </Text>
              </div>
            </div>
          </Carousel.Slide>
          <Carousel.Slide>
            <div style={carouselContent}>
              <div className="imageHalfStyle">
                <img src={require('../Images/trivium-api-full.png')} alt={'sample1'} />
              </div>
              <div style={{height: '50%'}}>
                <Title order={2}>Trivium API</Title>
                <Text>
                  Trivium API is a free high-reliability API for multiple networks, providing RPC, LCD, and wgRPC endpoints.<br/>
                  <Anchor href="https://api.trivium.network">https://api.trivium.network</Anchor>
                </Text>
              </div>
            </div>
          </Carousel.Slide>
          {/* <Carousel.Slide>
            <div style={carouselContent}>
              <div className="imageHalfStyle">
                <img src={require('../Images/Products_blackbox.png')} alt={'sample1'} />
              </div>
              <div style={{height: '50%'}}>
                <Title order={2}>BlackBox</Title>
                <Text>
                  BlackBox is a collection of tools allowing you send and receive SCRT anonymously.<br/>
                  <Anchor href="https://blackbox.cash">https://blackbox.cash</Anchor>
                </Text>
              </div>
            </div>
          </Carousel.Slide> */}
          <Carousel.Slide>
            <div style={carouselContent}>
              <div className="imageHalfStyle">
                <img src={require('../Images/certup.png')} alt={'sample1'} />
              </div>
              <div style={{height: '50%'}}>
                <Title order={2}>CertUP</Title>
                <Text>
                  CertUP is a platform for digital certifications using Secret Network&apos;s private smart contracts.<br/>
                  <Anchor href="https://certup.net">https://certup.net</Anchor>
                </Text>
              </div>
            </div>
          </Carousel.Slide>
          <Carousel.Slide>
            <div style={carouselContent}>
              <div className="iconHalfStyle">
                <FaTools size={140} />
              </div>
              <div style={{height: '50%'}}>
                <Title order={2}>Secret Tools</Title>
                <Text>
                    A collection of simple tools to better view your private data on Secret Network.<br />
                  <Link to="/tools" state={{ scroll_to: 'top' }}>https://trivium.network/tools</Link>
                </Text>
              </div>
            </div>
          </Carousel.Slide>
        </Carousel>
      </Container>
    </section>
  );
};

export default OurProducts;