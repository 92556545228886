import { useMantineTheme, Container, Text, Title, Grid, Card, Image, Badge, Button, Group, Anchor, Loader } from '@mantine/core';
import { Chain } from '../Data/chains';
import axios, { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';

interface Params {
    annualProvisions: number;
    blocksPerYear: number;
    inflation: number;
    bondedTokens: number;
    communityTax: number;
    commission: number;
}

async function getParams(lcdApi: AxiosInstance, validator: string, foundation = false): Promise<Params> {
  let response = await lcdApi.get('/cosmos/mint/v1beta1/annual_provisions');

  const annualProvisions = Number(response.data.annual_provisions); //string
  response = await lcdApi.get('/cosmos/mint/v1beta1/params');
  const blocksPerYear =  Number(response.data.params.blocks_per_year); //string
  const denom = response.data.params.mint_denom;

  response = await lcdApi.get('/cosmos/mint/v1beta1/inflation');
  const inflation = Number(response.data.inflation); //string

  response = await lcdApi.get('/cosmos/staking/v1beta1/pool');
  const bondedTokens = Number(response.data.pool.bonded_tokens); //string

  response = await lcdApi.get('/cosmos/distribution/v1beta1/params');
  let communityTax = Number(response.data.params.community_tax); //string

  response = await lcdApi.get(`/cosmos/staking/v1beta1/validators/${validator}`);
  const commission = Number(response.data.validator.commission.commission_rates.rate); //string

  // Add foundation tax to community tax to get an accurate APR for Secret Network
  if (foundation) {
    response = await lcdApi.get('/cosmos/distribution/v1beta1/foundation_tax');
    communityTax = communityTax + Number(response.data.tax); //string
  }

  return {
    annualProvisions,
    blocksPerYear,
    inflation,
    bondedTokens,
    communityTax,
    commission
  };
}

function calculateNominalAPR(params: Params): number {
  return (params.annualProvisions * (1 - params.communityTax) / params.bondedTokens) * (1 - params.commission);
}

async function getBlocksPerYearReal(lcdApi: AxiosInstance): Promise<number> {
  let response = await lcdApi.get('/cosmos/base/tendermint/v1beta1/blocks/latest');
  const block1 = response.data.block.header;
  const blockRange = Number(block1.height) > 10000 ? 10000 : 1;

  response = await lcdApi.get('/cosmos/base/tendermint/v1beta1/blocks/' + (Number(block1.height) - blockRange));
  const block2 = response.data.block.header;

  const yearMilisec = 31536000000;
  const blockMilisec = (new Date(block1.time).getTime() - new Date(block2.time).getTime()) / blockRange;
  return Math.ceil(yearMilisec / blockMilisec);
}

function calculateRealAPR(params: Params, nominalAPR: number, blocksYearReal: number) {
  const blockProvision = params.annualProvisions / params.blocksPerYear;
  const realProvision = blockProvision * blocksYearReal;
  return nominalAPR * (realProvision / params.annualProvisions);
}

interface Props {
    chain: Chain;
}

const NetworkTile = ({ chain }: Props) => {
  const theme = useMantineTheme();

  const [loadingApr, setLoadingApr] = useState(true);
  const [chainApr, setChainApr] = useState(0);
  const [aprFailed, setAprFailed] = useState(false);

  useEffect(()=> {
    getApr();
  }, [chain]);

  const gridStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    height: '270px',
  };

  async function getApr() {
    try {
      setLoadingApr(true);
      setAprFailed(false);
      const lcdApi = axios.create({
        baseURL: chain.restApi,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },
        timeout: 10000,
      });
      
      const params = await getParams(lcdApi, chain.valAddress, chain.chainId.includes('secret'));
      const blocksYearReal = await getBlocksPerYearReal(lcdApi);
      const nominalAPR = calculateNominalAPR(params);
      const actualAPR = calculateRealAPR(params, nominalAPR, blocksYearReal);
      
      // console.log(`Nominal APR: ${nominalAPR * 100} %`);
      // console.log(`RealTime APR: ${actualAPR * 100} %`);
      setChainApr(nominalAPR*100);
    } catch (error) {
      //console.error(error);
      setAprFailed(true);
    } finally {
      setLoadingApr(false);
    }
  }

  return (
    <Grid.Col xs={12} sm={4} md={4} lg={4}>
      <Card shadow="sm" p="lg" style={gridStyle}>
        <Card.Section style={{margin: '10% 10% 0 10%', display: 'flex', alignItems: 'center', flexGrow: '1'}}>
          {/* <Image style={{}} src={require(`../Images/${chain.image}`)} alt={chain.name} /> */}
          <Image style={{}} src={`chains/${chain.image}`} alt={chain.name} />
        </Card.Section>

        <div>
          <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
            <Text weight={500}>{chain.name}</Text>
            { !aprFailed &&
                            <Badge color="white" variant="light">
                              { loadingApr ?
                                <><Loader size={10}/> APR</>
                                :
                                <>{chainApr.toFixed(2)}% APR</>
                              }
                            </Badge>
            }
          </Group>
          <Anchor href={`${chain.pingMirror}/staking/${chain.valAddress}`} target="_blank" rel="noreferrer noopener">
            <Button variant="light" color="white" fullWidth mt="14px">
                Stake Now
            </Button>
          </Anchor>
        </div>
      </Card>
    </Grid.Col>
  );
};

export default NetworkTile;