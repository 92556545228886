import { Text, Container, MediaQuery, Button } from '@mantine/core';
import { MdOutlineArrowDownward } from "react-icons/md";
import { Link } from 'react-scroll';

const About = () => {

    return (
        <section id="about">
            <Container fluid>

                <div className="about-content">

                    <div style={{ marginBottom: 15 }}>
                        <Text transform="uppercase" weight={500} color="gray" className="bunkenFont">
                            T R I V I U M
                        </Text>
                    </div>

                    <div style={{ marginBottom: 15 }}>
                        <Text>
                            <MediaQuery query="(max-width: 768px)" styles={{ fontSize: '2.8rem !important' }}>
                                <h1 className="title">Privacy Matters</h1>
                            </MediaQuery>
                        </Text>
                    </div>

                    <div style={{ marginBottom: 25 }}>
                        <Text size="xl">
                            Trivium provides validator services to serveral networks, and develops privacy-preserving dApps.
                        </Text>
                    </div>

                    <div className="buttons">
                        <Link to="mission" smooth duration={500}>
                            <Button type="button" rightIcon={<MdOutlineArrowDownward size={16} />} radius="lg" size="md">Learn more</Button>
                        </Link>

                        <Link to="networks" smooth duration={500}>
                            <Button type="button" variant="default" radius="lg" size="md">Stake now</Button>
                        </Link>
                    </div>

                </div>

            </Container>

        </section>
    );
};

export default About;