import { Accordion, Text, Container, Title, Badge } from '@mantine/core';
import { FiPercent, FiCloudSnow, FiAnchor, FiServer, FiTriangle } from 'react-icons/fi';
import { TbZoomMoney } from 'react-icons/tb';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import { FaGavel } from 'react-icons/fa';

const FAQ = () => {
  //const theme = useMantineTheme();

  return (
    <section id="faq">
      <Container>
        <div style={{ marginBottom: 30 }}>
          <Text>
            <Title order={1} style={{ marginTop: 10 }}>Frequently Asked Questions</Title>
          </Text>
        </div>

        <Accordion variant="contained">
          <Accordion.Item value="item1">
            <Accordion.Control icon={<TbZoomMoney size={20} />}>
              Why should I stake with you?
            </Accordion.Control>
            <Accordion.Panel>We are a group of advanced technical users and developers. The idea of private blockchains excites us a lot and we have big plans to continue build privacy preserving dApps in the future. By staking with us you support our building aspirations to help further privacy!</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="item2">
            <Accordion.Control icon={<FiPercent size={20} />}>
              What is your commmission percentage?
            </Accordion.Control>
            <Accordion.Panel>Currently, we charge under 3% commission on all the network we validate! Commission is used to maintain our validator, API, and dApp infrastructure.</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="item3">
            <Accordion.Control icon={<VscWorkspaceTrusted size={20} />}>
              Why should I trust your node?
            </Accordion.Control>
            <Accordion.Panel>We are doing everything in our power to ensure the node stays up and running. With a technical team in different timezones around the globe, there&apos;s always someone watching the validator node performance. A sophisticated performance dashboard, in combination with direct message alerts, ensure our node is getting every attention it needs.</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="item4">
            <Accordion.Control icon={<FaGavel size={20} />}>
              Are you involved in governance?
            </Accordion.Control>
            <Accordion.Panel>Absolutely! We love to talk about the direction of the network and make sure your voice is also represented. Be sure to reach out to us on one of our channels to discuss our vote. We would love to hear from you!</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="item5">
            <Accordion.Control icon={<FiServer size={20} />}>
              What hardware are you running?
            </Accordion.Control>
            <Accordion.Panel>We only use enterprise grade hardware located in tier 3 datacenters around the world. Our modern, powerful and efficient servers are able to handle high loads with minimal power usage.</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="item6">
            <Accordion.Control icon={<FiTriangle size={20} />}>
                Where does the name Trivium come from?
            </Accordion.Control>
            <Accordion.Panel>
                It&apos;s a combination of the latin words <i>tri</i> (three) and <i>via</i> (road). <i>Trivium</i> is the place where 3 roads meet, refering to the three company founders.<br/>
                The term <i>Trivium</i> was also established in medieval educatioal theory, referring to the three foundational elements of knowledge: <i>grammar, logic and rhetoric.</i>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );

};

export default FAQ;