import { useMantineTheme, Container, Text, Title, Grid, Card, Image, Badge, Button, Group } from '@mantine/core';

const OurMission = () => {
  const theme = useMantineTheme();

  return (
    <section id="mission">
      <Container>
        <Text align="center">
          <Title order={1} mb="30px">Our Mission</Title>
        </Text>

        <Grid>
          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <Card shadow="sm" p="lg" style={{ height: '100%'}}>
              {/* <Card.Section>
                                <Image src={require('../Images/lime-welcome.png')} alt={'sample1'} />
                            </Card.Section> */}

              <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                <Text weight={600} size="xl">High Uptime</Text>
              </Group>

              <Text size="sm">
                            With a technical team in different timezones around the globe, there&apos;s always someone monitoring node performance. A sophisticated performance dashboard, in combination with direct message alerts, ensure our node is getting the attention it needs to maintain 24/7 uptime.
              </Text>
            </Card>
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <Card shadow="sm" p="lg" style={{ height: '100%' }}>
              {/* <Card.Section>
                                <Image src={require('../Images/lime-canoeing.png')} alt={'sample1'} />
                            </Card.Section> */}

              <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                <Text weight={600} size="xl">Privacy Protection</Text>
              </Group>

              <Text size="sm">
                                Privacy is having a choice – it is the right to decide who we tell what, to establish boundaries, to limit who has access to our information, location and data. We believe privacy is here to protect the individual, we prioritize privacy in everything we make.
              </Text>
            </Card>
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <Card shadow="sm" p="lg" style={{ height: '100%' }}>
              {/* <Card.Section>
                                <Image src={require('../Images/lime-message-sent.png')} alt={'sample1'} />
                            </Card.Section> */}

              <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                <Text weight={600} size="xl">Contribution</Text>
              </Group>

              <Text size="sm">
                                We strive to bring new value to the ecosystems of the networks we support. Our team consists of passionate developers, and we focus on building new stuff for you.
              </Text>
            </Card>
          </Grid.Col>
        </Grid>

      </Container>
    </section>
  );
};

export default OurMission;