import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Content from "../Views/MainContent";

export default function Main() {

  return (
    <>
      <Header page="home" />
      <Content />
      <Footer />
    </>
  )
}