import { Button, Burger, Drawer, Title, Anchor, Image, useMantineTheme } from '@mantine/core';
import React, { useEffect } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import { useLocation } from 'react-router-dom';

interface Props {
    page: 'home' | 'tools';
}

const Header = ({page}: Props) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = React.useState(false);

  const location = useLocation();
  const { scroll_to } = location.state || { scroll_to: undefined };

  useEffect(()=>{
    if (scroll_to){
      scroller.scrollTo(scroll_to, {
        smooth: false,
        duration: 0,
      });
    }
  });

  const title = opened ? 'Close navigation' : 'Open navigation';

  const getScrollLink = (text: any, destination: string) => {
    if (page === 'home') {
      return (
        <ScrollLink to={destination} smooth duration={500} style={anchorStyle}>{text}</ScrollLink>
      );
    }
    else {
      return (
        <Link to='/' state={{ scroll_to: destination }} style={anchorStyle}>{text}</Link>
      );
    }
  };

  const anchorStyle={
    color: theme.colorScheme === 'dark' ? '#fff' : '#000',
  };

  return (
    <header>
      <div className="content-desktop">
        <div className="logo-container">
          {getScrollLink(<Image src="Trivium-black.svg" alt={'logo'} style={{ width: '100%', filter: theme.colorScheme === 'dark' ? 'invert(99%) sepia(74%) saturate(422%) hue-rotate(202deg) brightness(117%) contrast(100%)' : undefined }} />, 'about')}
        </div>
        <div className="navbar">
          <div className="navbar-item scroll_buttons">
            { getScrollLink('Products', 'products') }
          </div>
          <div className="navbar-item scroll_buttons">
            { getScrollLink('FAQs', 'faq') }
          </div>
          <div className="navbar-item scroll_buttons">
            { getScrollLink('Contact', 'contact') }
          </div>
          <Button onClick={() => redirectToLink('https://explorer.trivium.network')}>Explorer</Button>
          { page === 'tools' ?
            <Button className="selectedBtn" disabled={true}>Tools</Button>
            : 
            <Link to="/tools">
              <Button>Tools</Button>
            </Link>
          }
          <Button onClick={() => redirectToLink('https://github.com/TriviumNode')}>Github</Button>
          <ThemeToggle />
        </div>
      </div>

      <div className="content-mobile">
        <div className='logo-container'>
          {getScrollLink(<Image src="Trivium-black.svg" alt={'logo'} style={{ width: '100%', filter: theme.colorScheme === 'dark' ? 'invert(99%) sepia(74%) saturate(422%) hue-rotate(202deg) brightness(117%) contrast(100%)' : undefined }} />, 'about')}
          
        </div>
        <div className="navbar">
          <ThemeToggle />
          <div className="burger-button">
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              title={title}
              size="lg"
            />
          </div>
        </div>


      </div>
      <Drawer
        transition="rotate-right"
        transitionDuration={250}
        transitionTimingFunction="ease"
        overlayOpacity={0.55}
        position="right"
        closeButtonLabel="Close drawer"
        title="Menu"
        padding="xl"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className="menu">
          <div className="menu-items">
            <div className="menu-item">
              { getScrollLink((<Title order={2}>{'Products & Services'}</Title>), 'products') }
            </div>
            <div className="menu-item">
              { getScrollLink((<Title order={2}>FAQs</Title>), 'faq') }
            </div>
            <div className="menu-item">
              { getScrollLink((<Title order={2}>Contact</Title>), 'contact') }
            </div>
            <div className="menu-item">
              <a href="https://explorer.trivium.network">
                <Title order={2}>Block Explorer</Title>
              </a>
            </div>
            <div className="menu-item">
              { page === 'tools' ?
                <Title order={2} color="blue">Tools</Title>
                : 
                <Link to="/tools">
                  <Title order={2}>Tools</Title>
                </Link>
              }
            </div>
            <div className="menu-item"><Anchor href="https://github.com/TriviumNode" target="_blank" rel="noopener noreferrer" onClick={() => setOpened(false)}><Title order={2}>Github</Title></Anchor></div>
          </div>
        </div>
      </Drawer>
    </header>
  );
};

export default Header;

const redirectToLink = (link: string): void => {
  window.open(link, '_blank');
};