import { useMantineTheme, Container, Text, Title, Grid, Card, Image, Badge, Button, Group, Anchor } from '@mantine/core';


import { BsDiscord, BsTelegram, BsTwitter } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const ContactUs = () => {
    const theme = useMantineTheme();

    const anchorStyle={
        color: theme.colorScheme === "dark" ? '#fff' : '#000',
    }

    const itemStyle={
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    }

    return (
        <section id="contact">
            <Container>
                <Text align="center">
                    <Title order={1} mb="30px">Contact Us</Title>
                </Text>

                <Text align="center" mb="25px">
                Want to know more about us?<br/>
                Feel free to reach out on one of the following platforms!
                </Text>

                <Grid>
                    <Grid.Col xs={6} sm={6} md={3} lg={3}>
                        <Anchor href="https://discord.gg/tZj7ZhhdP5" target="_blank" rel="noreferrer noopener" style={anchorStyle}>
                        <div
                            style={itemStyle}
                        >
                                <BsDiscord size={50} />
                                <div style={{marginLeft: '.75rem'}}>
                                    <Text weight={600} size="xl">Discord</Text>
                                    <Text size="sm">Trivium Node</Text>
                                </div>
                            </div>
                        </Anchor>
                    </Grid.Col>
                    <Grid.Col xs={6} sm={6} md={3} lg={3}>
                        <Anchor href="https://twitter.com/TriviumNode" target="_blank" rel="noreferrer" style={anchorStyle}>
                        <div
                            style={itemStyle}
                        >
                                <BsTwitter size={50} />
                                <div style={{marginLeft: '.75rem'}}>
                                    <Text weight={600} size="xl">Twitter</Text>
                                    <Text size="sm">@TriviumNode</Text>
                                </div>
                            </div>
                        </Anchor>
                    </Grid.Col>
                    <Grid.Col xs={6} sm={6} md={3} lg={3}>
                        <Anchor href="https://t.me/TriviumNode" target="_blank" rel="noreferrer" style={anchorStyle}>
                        <div
                            style={itemStyle}
                        >
                               <BsTelegram size={50} />
                                <div style={{marginLeft: '.75rem'}}>
                                    <Text weight={600} size="xl">Telegram</Text>
                                    <Text size="sm">Trivium Node</Text>
                                </div>
                            </div>
                        </Anchor>
                    </Grid.Col>
                    {/* <Grid.Col xs={6} sm={6} md={3} lg={3}>
                        <Anchor href="mailto:info@trivium.network" target="_blank" rel="noreferrer" style={anchorStyle}>
                            <div
                                style={itemStyle}
                            >
                                <MdEmail size={50} />
                                <div style={{marginLeft: '.75rem'}}>
                                    <Text weight={600} size="xl">Email</Text>
                                    <Text size="sm">info@trivium.network</Text>
                                </div>
                            </div>
                        </Anchor>
                    </Grid.Col> */}
                </Grid>

            </Container>
        </section>
    );
};

export default ContactUs;