import { useMantineTheme, Container, Text, Title, Grid, Card, Image, Badge, Button, Group } from '@mantine/core';
import chains from '../Data/chains';
import NetworkTile from './NetworkTile';

const Networks = () => {
  const theme = useMantineTheme();

  const gridStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    height: '270px',
  }

  return (
    <section id="networks">
      <Container>
        <Text align="center">
          <Title order={1} mb="30px">Networks</Title>
        </Text>
        <Text align="center" mb="25px">
                    You can stake with us on all your favorite networks!
        </Text>

        <Grid  justify="center">
          { chains.map(chain=>{
            return (
              <NetworkTile chain={chain} key={`network-${chain.cosmosId}`} />
            )
          })}
          {/* <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <Card shadow="sm" p="lg" style={gridStyle}>
                            <Card.Section style={{margin: '10% 10% 0 10%'}}>
                                <Image style={{}} src={require('../Images/secret.png')} alt={'Secret'} />
                            </Card.Section>

                            <div>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={500}>Secret Network</Text>
                                    <Badge color="white" variant="light">
                                        xxx % APR
                                    </Badge>
                                </Group>

                                <Button variant="light" color="white" fullWidth mt="14px">
                                    Stake Now
                                </Button>
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <Card shadow="sm" p="lg" style={gridStyle}>
                            <Card.Section style={{margin: '10% 10% 0 10%'}}>
                                <Image src={require('../Images/sentinel.png')} alt={'Sentinel'} />
                            </Card.Section>
                            <div>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={500}>Sentinel</Text>
                                    <Badge color="white" variant="light">
                                        xxx % APR
                                    </Badge>
                                </Group>

                                <Button variant="light" color="white" fullWidth mt="14px">
                                    Stake Now
                                </Button>
                            </div>
                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <Card shadow="sm" p="lg" style={gridStyle}>
                            <Card.Section style={{margin: '10% 10% 0 10%'}}>
                                <Image src={require('../Images/secret.png')} alt={'sample1'} />
                            </Card.Section>

                            <div>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={500}>Akash</Text>
                                    <Badge color="white" variant="light">
                                    xxx % APR
                                    </Badge>
                                </Group>

                                <Button variant="light" color="white" fullWidth mt="14px">
                                    Stake Now
                                </Button>
                            </div>
                        </Card>
                    </Grid.Col> */}
        </Grid>

      </Container>
    </section>
  );
};

export default Networks;