import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Content from '../Views/ToolsContent';

export default function Tools() {

  return (
    <>
      <section id="top">
        <Header page="tools" />
      </section>
      <Content />
      <Footer />
    </>
  );
}